import * as React from "react"
import { useState } from "react"
import "./countCombo.scss"

import SelectFull from "./SelectFull"
import Form from "../form/Form"

export default function CountCombo(props) {
  const { data, discount, classAdd } = props

  const [activeIndex, setActiveIndex] = useState([0, 1])

  const setFirstIndex = i => {
    activeIndex[0] = i
    setActiveIndex([activeIndex[0], activeIndex[1]])
  }
  const setSecondIndex = i => {
    activeIndex[1] = i
    setActiveIndex([activeIndex[0], activeIndex[1]])
  }

  return (
    <div className={classAdd ? `${classAdd} сountCombo` : "сountCombo"}>
      <div className="container">
        <div className="сountCombo__cont">
          <div className="сountCombo__text">
            <h2>Посчитай свое комбо</h2>
            <p>
              Выбери два курса и получи <span>скидку</span>
            </p>
          </div>

          <div className="сountCombo__form">
            <div className="сountCombo__form-box selectFull">
              <SelectFull
                data={data}
                activeIndex={activeIndex}
                startIndex={0}
                setIndex={setFirstIndex}
              />
              <div className="сountCombo__form-plus" />
            </div>

            <div className="сountCombo__form-box selectFull--second">
              <SelectFull
                data={data}
                activeIndex={activeIndex}
                startIndex={1}
                setIndex={setSecondIndex}
              />
            </div>

            <div className="сountCombo__form-box">
              <div className="сountCombo__price">
                <span className="сountCombo__price-title">Итого</span>
                <span className="сountCombo__price-value">
                  {Intl.NumberFormat("Ru-ru", {
                    style: "currency",
                    currency: "RUB",
                    minimumFractionDigits: 0,
                  }).format(
                    ((Number(data[activeIndex[0]].price) +
                      Number(data[activeIndex[1]].price)) *
                      (100 - discount)) /
                      100
                  )}
                </span>
              </div>

              <div className="сountCombo__oldprice">
                <span className="сountCombo__oldprice-title">Старая цена</span>
                <span className="сountCombo__oldprice-value">
                  {Intl.NumberFormat("Ru-ru", {
                    style: "currency",
                    currency: "RUB",
                    minimumFractionDigits: 0,
                  }).format(
                    Number(data[activeIndex[0]].price) +
                      Number(data[activeIndex[1]].price)
                  )}
                </span>
              </div>

              <div className="сountCombo__saving">
                <span className="сountCombo__saving-title">Экономия</span>
                <span className="сountCombo__saving-value">
                  {Intl.NumberFormat("Ru-ru", {
                    style: "currency",
                    currency: "RUB",
                    minimumFractionDigits: 0,
                  }).format(
                    ((Number(data[activeIndex[0]].price) +
                      Number(data[activeIndex[1]].price)) *
                      discount) /
                      100
                  )}
                </span>
              </div>
            </div>

            <div className="сountCombo__form-box">
              <Form />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
