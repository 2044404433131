import * as React from "react"
import { motion } from "framer-motion"
import Img from "react-cool-img"

import { STRAPI_API } from "../consts/api"

// setVideoSrc, setFormPopup, setPopUpVideo - state's внешних popUp
// posterVideoSrc элемент может содержать на бекгрануде видео
// posterImgSrc элемент может содержать на бекгрануде фото
// classAdd - доп класс для особенной стилизации

// headData - это обект из свойств: { title, subtitle, mainButton, video.src (если преполагается, что есть кнопка для
// открытия popup - // play video) }

export default function HeadComp(props) {
  const {
    headData,
    setVideoSrc,
    posterImgSrc,
    posterVideoSrc,
    setPopUpVideo,
    setFormPopup,
    classAdd,
  } = props

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
      transition={{ duration: 0.5, ease: "easeInOut" }}
      className={classAdd ? `${classAdd} head` : "head"}
    >
      <div className="head__fon">
        <div className="head__fon--background" />

        {posterVideoSrc && (
          <div className="head__fon--video">
            <video autoPlay muted loop playsInline src={posterVideoSrc} />
          </div>
        )}
      </div>

      <div className="container">
        <div className="head__cont">
          {headData?.title && (
            <div className="head__text">
              <h1>{headData.title}</h1>

              {headData?.subtitle && <p>{headData.subtitle}</p>}
            </div>
          )}

          {headData?.mainButton && (
            <button className="button" onClick={() => setFormPopup(true)}>
              {headData.mainButton}
              <span className="flare"></span>
            </button>
          )}
        </div>

        {headData?.mainVideo.url && (
          <button
            className="button-play"
            onClick={() => {
              setVideoSrc(STRAPI_API + headData?.mainVideo.url)
              setPopUpVideo(true)
            }}
            aria-label="play video"
          >
            <span />
          </button>
        )}

        {posterImgSrc && headData?.mainVideo.url && (
          <div className="boxImg">
            <Img
              src={posterImgSrc}
              alt={`Изображение курса ${headData.title}`}
            />
          </div>
        )}

        {
          posterImgSrc && ! headData?.mainVideo.url && (
                <Img
                    className="boxImg--only-poster"
                    src={posterImgSrc}
                    alt={`Изображение курса ${headData.title}`}
                />
            )
        }
      </div>
    </motion.div>
  )
}
