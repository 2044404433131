import * as React from "react"
import { useEffect, useState } from "react"
import { AnimatePresence } from "framer-motion"

import "./combo/combo.scss"

import ComboImg from "../../static/media/combo.webp"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import VideoPopup from "../components/popups/VideoPopup"
import FormPopup from "../components/popups/FormPopup"
import CountCombo from "../components/combo/countCombo"
import PopularCourses from "../components/cards/PopularCourses"
import Title from "../components/Title"
import HeadComp from "../components/HeadComp"
import { STRAPI_API } from "../consts/api"
import { SCREEN_WIDTH_TABLET } from "../consts/SizeScreens"
import {SEO} from "../components/seo";

const Combo = ({ data, location }) => {
  const { strapiComboPage } = data

  const [popUpVideo, setPopUpVideo] = useState(false)
  const [videoSrc, setVideoSrc] = useState("")

  const [formPopup, setFormPopup] = useState(false)

  const [courseArr, setCourseArr] = useState([])

  useEffect(() => {
    if (strapiComboPage?.Courses && window.innerWidth < SCREEN_WIDTH_TABLET) {
      const arr = [...strapiComboPage?.Courses?.slice(0, 3)]
      setCourseArr(arr)

      const setLengthCourseArr = () => {
        if (window.innerWidth > SCREEN_WIDTH_TABLET) setCourseArr([])
      }

      window.addEventListener("resize", setLengthCourseArr)

      return () => {
        window.removeEventListener("resize", setLengthCourseArr)
      }
    }
  }, [data])

  const mapHeadData = data => {
    return {
      mainVideo: {
        url: data?.Video.url,
      },
      title: data?.title,
      subtitle: (
        <>
          <span>Выбери два курса и получи</span>
          <span className={"combo-price"}>
            &nbsp;скидку {data.DiscountPercent}%
          </span>
        </>
      ),
      mainButton: data?.button,
    }
  }

  const mapCountData = data => {
    return data.map(item => {
      return {
        img:
          STRAPI_API +
          item.CourseCard.boxImg.url +
          "?format=webp&width=40&grayscale=true",

        name: item.CourseCard.title,
        price: item.price,
      }
    })
  }

  return (
    <div>
      <SEO title={strapiComboPage?.titleDesc?.pageTitle} description={strapiComboPage?.titleDesc?.pageDescription}/>
      <Layout classAdd="comboPage" location={location}>
        <HeadComp
          headData={mapHeadData(strapiComboPage)}
          setVideoSrc={setVideoSrc}
          setPopUpVideo={setPopUpVideo}
          setFormPopup={setFormPopup}
          posterImgSrc={ComboImg}
          classAdd="comboPageHead"
        />

        {
          // COMBO COURSES CARDS
          strapiComboPage?.Courses && (
            <div>
              <div className="container">
                <Title
                  value={[
                    ["dark", "Курсы, на которые действует "],
                    ["red", "акция"],
                  ]}
                  classAdd="popularCourses--title m140"
                />
              </div>

              <div className="comboCourses m60_40">
                <PopularCourses
                  setVideoSrc={setVideoSrc}
                  setPopUpVideo={setPopUpVideo}
                  data={
                    courseArr.length > 0 ? courseArr : strapiComboPage.Courses
                  }
                />
              </div>
            </div>
          )
        }

        {
          // MORE BUTTON
          courseArr.length !== 0 &&
            courseArr.length <= strapiComboPage?.Courses?.length && (
              <div className="container moreButton">
                <button
                  type={"button"}
                  aria-label={"Показать еще 3 курса"}
                  onClick={() => {
                    setCourseArr(...courseArr, [
                      ...strapiComboPage?.Courses?.slice(
                        courseArr.length - 1,
                        3
                      ),
                    ])
                  }}
                >
                  Показать еще 3 курса
                </button>
              </div>
            )
        }

        {
          // COUNTER
          strapiComboPage?.Courses && (
            <CountCombo
              classAdd="m140"
              discount={strapiComboPage.DiscountPercent}
              buttonCalculator={strapiComboPage.buttonCalculator}
              data={mapCountData(strapiComboPage.Courses)}
            />
          )
        }
      </Layout>

      <AnimatePresence
        initial={false}
        exitBeforeEnter={true}
        onExitComplete={() => null}
      >
        {popUpVideo && videoSrc && (
          <VideoPopup
            open={popUpVideo}
            setOpen={setPopUpVideo}
            videoSrc={videoSrc}
          />
        )}

        {formPopup && <FormPopup open={formPopup} setOpen={setFormPopup} />}
      </AnimatePresence>
    </div>
  )
}

export const pageQuery = graphql`
  query {
    strapiComboPage {
      buttonCalculator
      title
      DiscountPercent
      button
      Video {
        url
      }
      Courses {
        slug
        strapi_id
        price
        video {
          url
        }
        CourseCard {
          title
          subtitle
          boxImg {
            url
          }
          item1 {
            title
            subtitle
          }
          item2 {
            title
            subtitle
          }
          item3 {
            title
            subtitle
          }
          item4 {
            title
            subtitle
          }
          
        }
      }
      titleDesc {
        pageTitle
        pageDescription
      }
    }
  }
`

export default Combo
