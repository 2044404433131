import * as React from "react"
import PopularCoursesCard from "./PopularCoursesCard"
import DraggableCards from "./DraggableCards"

export default function PopularCourses(props) {
  const { data, setVideoSrc, setPopUpVideo } = props

  return (
    <DraggableCards classAdd={"cardAnimation"} mobileDisable={true}>
      {data.map((item, index) => (
        <PopularCoursesCard
          key={index}
          data={item}
          setVideoSrc={setVideoSrc}
          setPopUpVideo={setPopUpVideo}
        />
      ))}
    </DraggableCards>
  )
}
