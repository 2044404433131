import * as React from "react"

import CardAnimation from "./CardAnimation"
import Img from "react-cool-img"
import { STRAPI_API } from "../../consts/api"
import { MENU_LINKS } from "../../consts/MenuLinks"
import { Link } from "gatsby"

export default function PopularCoursesCard(props) {
  const { data, setVideoSrc, setPopUpVideo } = props

  function openVideoPopup(e, url) {
    e.preventDefault()
    setVideoSrc(STRAPI_API + url)
    setPopUpVideo(true)
  }

  return (
    <CardAnimation cardClass={"popularCourses"}>
      <Link to={MENU_LINKS.course + `${data.slug}`} draggable={false}>
        <div className="popularCourses__box-top">
          <div className="popularCourses__box-top-cont">
            <span className="popularCourses__box-title">
              {data.CourseCard.title}
            </span>
            <div className="popularCourses__box-text">
              <p>{data.CourseCard.subtitle}</p>
            </div>
          </div>
          <div className="popularCourses__box-top-cont">
            <Img
              className="popularCourses__box-img"
              src={STRAPI_API + data.CourseCard.boxImg.url + "?height=60"}
              width="auto"
              height="60"
              alt="img"
              lazy={false}
            />
          </div>
        </div>

        <ul className="popularCourses__box-list">
          <li>
            <b>{data.CourseCard.item1?.title}</b>
            <span>{data.CourseCard.item1?.subtitle}</span>
          </li>
          <li>
            <b>{data.CourseCard.item2?.title}</b>
            <span>{data.CourseCard.item2?.subtitle}</span>
          </li>
          <li>
            <b>{data.CourseCard.item3?.title}</b>
            <span>{data.CourseCard.item3?.subtitle}</span>
          </li>
          <li>
            <b>{data.CourseCard.item4?.title}</b>
            <span>{data.CourseCard.item4?.subtitle}</span>
          </li>
        </ul>
        <div className="popularCourses__box-foot">
          <button className="popularCourses__box-more">Подробнее</button>

          {
            //  BUTTON FOR VIDEO POPUP
            data?.video?.url && (
              <button
                onClick={e => openVideoPopup(e, data.video.url)}
                className="popularCourses__box-video"
              >
                Видео о курсе
              </button>
            )
          }
        </div>
      </Link>
    </CardAnimation>
  )
}
