import * as React from "react"
import { motion, useSpring, useTransform } from "framer-motion"

export default function CardAnimation(props) {
  const { cardClass, children } = props

  const x = useSpring(200, { stiffness: 300 })
  const y = useSpring(200, { stiffness: 300 })

  const rotateX = useTransform(y, [0, 384], [2, -2])
  const rotateY = useTransform(x, [0, 448], [-2, 2])

  function handleMouse(event) {
    const rect = event.currentTarget.getBoundingClientRect()
    x.set(event.clientX - rect.left)
    y.set(event.clientY - rect.top)
  }

  function handleMouseOut() {
    x.set(200)
    y.set(200)
  }

  return (
    <motion.div
      className={`${cardClass}__item drag__item`}
      style={{
        display: "flex",
        placeItems: "center",
        placeContent: "center",
        perspective: 400,
      }}
      whileTap={{ scale: 1.05 }}
      transition={{ duration: 0.5 }}
      whileHover={{ scale: 1.1 }}
      onMouseOut={handleMouseOut}
      onMouseMove={handleMouse}
    >
      <motion.div
        className={`${cardClass}__box`}
        transition={{ duration: 0.6 }}
        style={{
          rotateX,
          rotateY,
        }}
      >
        {children}
      </motion.div>
    </motion.div>
  )
}
