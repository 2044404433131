import * as React from "react"
import { useEffect, useRef, useState } from "react"
import Img from "react-cool-img"

export default function SelectFull(props) {
  const { data, classAdd, activeIndex, startIndex, setIndex } = props

  const [enable, setEnable] = useState(false)

  const ref = useRef(null)

  const changeSelect = index => {
    setEnable(false)
    setIndex(index)
  }

  const openSelect = () => {
    setEnable(!enable)
  }

  const closeSelect = e => {
    if (!ref.current.contains(e.target)) {
      setEnable(false)
    }
  }

  useEffect(() => {
    document.addEventListener("click", closeSelect, false)
    return document.removeEventListener("click", closeSelect, false)
  })

  const contSelect = data => {
    return (
      <>
        {data?.img && (
          <div className="select-full__box">
            <Img className="select-full__img" src={data.img} alt="img" />
          </div>
        )}

        {data?.name && data?.price && (
          <div className="select-full__box">
            <span className="select-full__name">{data.name}</span>
            <span className="select-full__price">{data.price + " ₽"}</span>
          </div>
        )}
      </>
    )
  }

  const listSelect = data.map((item, index) => {
    return (
      <li
        className={"active"}
        style={{ display: activeIndex.includes(index) ? "none" : "block" }}
        onClick={() => changeSelect(index)}
        key={index}
      >
        {contSelect(item)}
      </li>
    )
  })

  return (
    <div className={classAdd ? `${classAdd}` : ""}>
      {data.length && (
        <div
          className={`${enable ? "select-full active" : "select-full"}`}
          ref={ref}
        >
          <div className="select-full__title" onClick={() => openSelect()}>
            {contSelect(data[activeIndex[startIndex]])}
          </div>

          {enable && (
            <div className="select-full__list">
              <ul className="select-full__list-cont">{listSelect}</ul>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
